.benefitsSection {
  margin-top: 3%;
}

.benefitsHeading {
    font-family: "Lora";
    font-size: 50px;
    /* font-weight: bold; */
}

.benefitsText {
  font-family: "Josefin Sans";
  font-size: 18px;
}
.benefitsSecondary {
  color: #545454;
  font-family: "Josefin Sans";
  font-size: 16px;
}

.heroGroup {
  max-width: 100%;
  height: auto;
  min-height: 300px;
}

.bbreak {
  margin-top: 3%;
  margin-bottom: 5%;
}

a {
  text-decoration: none;
}

.fullWidthLine {
  width: 99vw;
    border: none;
    height: 1px; /* or the thickness you want for the line */
    background-color: #19B092; /* or any color you prefer */
    margin-left: calc(-50vw + 50%); /* centers the line if there's a vertical scrollbar */
    margin-bottom: 2%;
}

.genericButton {
  background-color: #19B092;
  font-size: 18px;
  height: 50px;
  width: auto;
  color: white;
  border: none;
  padding-left: 10%;
  padding-right: 10%;
  font-family: "Josefin Sans";
  border-radius: 10px;
  text-decoration: none;
}

.genericButton:hover {
  background-color: #09745e;
}


@media (max-width: 1350px) { 
  .benefitsHeading {
    font-size: 42px;
  }
  .benefitsText {
    font-size: 22px;
  }
}

@media (max-width: 1025px) {
  .benefitsHeading {
    font-size: 40px;
  }
  .benefitsText {
    font-size: 21px;
  }
  .genericButton {
    font-size: 16px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .benefitsSection {
    margin-top: 8%;
  }
  .benefitsHeading {
    font-size: 38px;
  }
  .benefitsText {
    font-size: 20px;
  }
  .genericButton {
    font-size: 16px;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5%;
  }
  .bbreak {
    margin-top: 9%;
    margin-bottom: 9%;
  }
}

@media (max-width: 480px) { /* Mobile phones */
    .benefitsHeading {
      font-size: 36px;
      
    }
    .benefitsText {
      font-size: 18px;
    }
    .genericButton {
      font-size: 16px;
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 5%;
    }
}


.clientPortalButton {
  background-color: #19B092;
  font-size: 16px;
  height: auto;
  padding: 5%;
  width: 220px;
  color: white;
  border: none;
  font-family: "Josefin Sans";
  border-radius: 10px;
}

.clientPortalButton:hover {
  background-color: #09745e;
}