.newsletterContainer {
    background-image: url("../../assets/images/Newsletter.svg");
    background-size: auto auto;
    background-color: #19B092;
    background-repeat: no-repeat;
    background-position: center center;
     /* Styles for the container */
    color: #fff; /* White text color */
    position: relative;
    border-radius: 15px;
  }
  .emailDiv {
    max-width: 770px;
    /* border: 1px solid #FFFFFF; */
    border-radius: 20px;
    height: 70px;
  }
  .newsletterTitle {
    margin-bottom: 1rem;
    font-size: 40px; /* Adjust size as needed */
    font-family: "Lora";
  }
  
  .newsletterDescription {
    margin-bottom: 2rem;
    font-size: 18px;
    max-width: 60vw;
    font-family: "Josefin Sans";
  }
  
  
  .newsletterButton {
    padding: 10px 40px; /* Adjust padding as needed */
    background-color: #16A387; /* Your button color */
    color: #fff; /* White text */
    border: none; /* No border */
    border-radius: 20px; /* Rounded corners */
    cursor: pointer;
  }
  
  .newsletterPrivacy {
    font-size: 16px; /* Adjust size as needed */
    color: #fff; /* White text color */
    font-family: "Josefin Sans";
  }

  .emailField {
    color: white;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: transparent;
    height: 100%;
    font-size: 18px;
  }

  .emailField::placeholder {
    color: white; /* Sets the placeholder text color to white */
    opacity: 1; /* Makes sure the placeholder text is not semi-transparent */
  }

.newsLetterSubscribe {
  color: #19B092;
  height: 100%;
  border: none;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  background-color: white;
  font-family: "Josefin Sans";
  font-weight: 600;
  font-size: 18px;
  padding-left: 4%;
  padding-right: 4%;
}

.newsLetterSubscribe:hover {
  color: white;
  background-color: #286b5e;
  border: 1px solid white;
}

@media (max-width: 1350px) { 
  .newsletterTitle {
    font-size: 36px;
  }
  .newsletterDescription {
    font-size: 16px;
  }

  .emailDiv {
    max-width: 400px;
    height: 60px;
  }
}

@media (max-width: 1025px) {
  .newsletterTitle {
    font-size: 34px;
  }
  .newsletterDescription {
    font-size: 14px;
  }
  .newsLetterSubscribe {
    font-size: 12px;
    padding-left: 4%;
    padding-right: 4%;
  }
  .emailDiv {
    max-width: 400px;
    height: 50px;
  }
  .newsletterPrivacy {
    font-size: 14px; /* Adjust size as needed */
  }
}
