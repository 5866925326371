.herotext {
    font-family: "Lora";
    font-size: 50px;
    font-weight: bold;
}

.herodesc {
  font-family: "Josefin Sans";
  font-size: 24px;
}

.heroGroup {
  max-width: 100%;
  height: auto;
  min-height: 300px;
}

a {
  text-decoration: none;
}

.genericButton {
  background-color: #19B092;
  font-size: 18px;
  height: 50px;
  width: auto;
  color: white;
  border: none;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: auto;
  font-family: "Josefin Sans";
  border-radius: 10px;
  text-decoration: none;
}

.genericButton:hover {
  background-color: #09745e;
}


@media (max-width: 1350px) { 
  .herotext {
    font-size: 42px;
  }
  .herodesc {
    font-size: 22px;
  }
}

@media (max-width: 1025px) {
  .herotext {
    font-size: 36px;
  }
  .herodesc {
    font-size: 18px;
  }
  .genericButton {
    font-size: 16px;
    height: auto;
  }
}

@media (max-width: 768px) {
    .herotext {
      font-size: 30px;
    }
    .herodesc {
      font-size: 15px;
    }
    .genericButton {
      font-size: 16px;
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 5%;
    }
}

@media (max-width: 480px) { /* Mobile phones */
    .herotext {
      font-size: 31px;
      
    }
    .herodesc {
      font-size: 18px;
    }
    .genericButton {
      font-size: 18px;
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 5%;
    }
}





