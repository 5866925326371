.socialButton {
    color: #19B092 !important;
    border-color: #19B092 !important;
    border-width: 1px !important;
    height: 40px;
    width: 40px;
}

.socialButton:hover {
    background-color: #19B092 !important;
    color: white !important;
}