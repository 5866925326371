
a {
    text-decoration: none;
}

.clientPortalButton {
    background-color: #19B092;
    font-size: 15px;
    height: 40px;
    width: 135px;
    color: white;
    border: none;
    font-family: "Josefin Sans";
    border-radius: 10px;
    text-decoration: none;
}

.clientPortalButton:hover {
    background-color: #09745e;
}

@media (max-width: 600px) { /* Mobile phones */
    .socialButton {
        transform: scale(10);
    }
}



.hamburger {
    background: none;
    border: none;
    display: none;
  }
  
  .navbarCollapse {
    display: flex; /* or inline-flex */
    align-items: center;
  }
  
  .collapsed {
    display: none;
  }
  
  /* Media queries */
  @media (max-width: 600px) {
    .hamburger {
      display: block; /* Show hamburger icon on small screens */
    }
    
    .navbarCollapse {
      /* display: none; Hide buttons by default on small screens */
    }
    
    .socialButton {
      transform: scale(1); /* Adjust scale if necessary */
      margin-right: 5px; /* Add spacing between buttons */
    }
    
    .clientPortalButton {
      padding: 8px 16px; /* Adjust button size on small screens */
      display: none;
    }
  }


  .mobileMenu {
    position: fixed;
    opacity: 100%;
    top: 0%;
    right: -200%; /* Start off the screen */
    width: 100%; /* Full width or adjust as needed */
    background: white;
    z-index: 1000;
    transition: right 0.5s; /* Smooth transition for sliding in */
    display: flex;
    /* align-items: center; */
    width: 100vw;
    height: 40vh;
    border-bottom: 2px solid #09745e;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .mobileMenuOpen {
    right: 0; /* Slide in */
  }
  
  .mobileMenuItem {
    margin: 10px 0;
  }